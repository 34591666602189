.about{
	&-text{
		max-width: 535px;
		width: 100%;
		margin: 0 auto;
		text-align: left;

		p{
			margin-bottom: 20px;
		}
	}
}

.themes{
	.stack{
		grid-gap: 30px;
		grid-template-columns: repeat(3, 1fr);
	}

	.stack__item{
		position: relative
	}
	.thumnbnail{
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;
		z-index: 0
	}
	.title{
		position: relative;
		z-index: 2;
		text-transform: uppercase;
		color: #fff;
		font-family: "PT Sans";
		font-weight: 700;
		font-size: 26px;
		line-height: 1.1538461538461537em;
	}
	.flip-box {
	  	background-color: transparent;
	  	width: 100%;
	  	perspective: 1000px; /* Remove this if you don't want the 3D effect */
	}

	/* This container is needed to position the front and back side */
	.flip-box-inner {
	  position: relative;
	  width: 100%;
	  height: 100%;
	  text-align: center;
	  transition: transform 0.5s ease-in-out;
	  transform-style: preserve-3d;
	}

	/* Do an horizontal flip when you move the mouse over the flip box container */
	.flip-box:hover .flip-box-inner {
	  transform: rotateY(180deg);
	}

	/* Position the front and back side */
	.flip-box-front, .flip-box-back {
	  position: absolute;
	  width: 100%;
	  height: 100%;
	  -webkit-backface-visibility: hidden; /* Safari */
	  backface-visibility: hidden;
	}

	/* Style the front side */
	.flip-box-front {
	  background-color: #bbb;
	  z-index: 1;
	  color: black;
	  display: flex;
	  align-items: center;
	  padding: 20px;
	  justify-content: center
	}

	/* Style the back side */
	.flip-box-back {
		z-index: 2;
		padding: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		background-color: $color_active;
		color: $color_main;
		transform: rotateY(180deg);

		&__title{
			color: $color_main;
			padding: 20px 0;
			font-weight: 400;
		}

		&__button{
			margin-top: 20px;
			border: solid 2px #000;
    		border-radius: 3px;
        	padding: 7px 20px;
            font-size: 10px;
            font-weight: 700;
            font-family: "PT Sans";
            transition: all .3s ease;
            letter-spacing: 1px;
            text-transform: uppercase;
            display: block;

            &:hover{
            	background: $color_main;
            	color: $color_text_ligt_active;
            }
		}
	}
}

.video{
	border-bottom: 1px solid #dddddd;
	.stack{
		grid-gap: 30px;
		grid-template-columns: repeat(4, 1fr);
	}

	iframe{
		max-width: 100%;
		height: auto;
	}	
}

.brands{
	.stack{
		img{
			filter: grayscale(10);
			cursor: pointer;
			opacity: .7;
			transition: all .3s ease;
			margin: 0 auto;
			&:hover{
				filter: grayscale(0);
				opacity: 1;
			}
		}
	}
	.brands-stack{
		margin-bottom: 25px;
	}
}

.testi{
	padding: 100px 0;
	.stack{
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap: 30px;
		grid-row-gap: 70px 
	}

	.stack__item{
		min-height: 240px;
		position: relative;
		border-radius: 3px;
	    padding: 20px;
	    border: 1px solid rgba(0,0,0,.05);
	    background-color: #fff;
	    box-shadow: 0 1px 2px 0 rgba(0,0,0,.05);
	    display: flex;
	    flex-direction: column;
	    justify-content: space-between;

	    .testi__text{
    		padding: 20px 20px 0;
    		font-size: 16.8px;
	    }

	    .testi__author{
	    	padding-left: 20px;
	    	align-items: center
	    }

	    .testi__avatar{
	    	width: 50px;
	    	height: 50px;
	    	border-radius: 50%;
	    	object-fit: cover;
	    	margin-right: 20px;
	    }

	    .testi-author__name{
	    	margin-bottom: 5px;
		    font-weight: 700;
		    font-size: 13px;
		    line-height: 11px;
	    }

	    .testi-author__profession{
	    	font-size: 12px;
		    line-height: 14px;
		    opacity: .8;
	    }

	    &::after{
	    	position: absolute;
		    bottom: -8px;
		    left: 55px;
		    display: block;
		    width: 14px;
		    height: 14px;
		    border: 2px solid rgba(0,0,0,.05);
		    border-top: none;
		    border-left: none;
		    background-color: #fff;
		    content: "";
		    transform: rotate(45deg);
	    }
	}
}

.colleagues{
	background: #F2F2F2;
	border-bottom: 1px solid $color_active;

	.stack{
		grid-template-columns: repeat(4, 1fr);
		grid-gap: 30px;
		margin-bottom: 50px;

		&__item{
			overflow: hidden;
			&:hover{
				.mask{
					left: 0;
					opacity: 1;
				}
			}
		}

		.mask{
			transition: all .5s ease;
			left: -100%;
			opacity: 0;
		}

		.mask::-webkit-scrollbar {
		  width: 4px;
		}
		 
		.mask::-webkit-scrollbar-track {
		  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		}
		 
		.mask::-webkit-scrollbar-thumb {
		  background-color: darkgrey;
		  outline: 1px solid slategrey;
		}

		.colleagues__name{
			font-size: 18px;
		    color: #404040;
		    font-weight: bold;
		    text-transform: uppercase;
		    margin-top: 10px;
		    margin-bottom: 40px;
		    position: relative;

		    &::after{
		    	content: "";
				display: block;
				position: absolute;
				bottom: -18px;
				width: 40%;
				height: 2px;
				left: 0;
				background: #333;
		    }
		}

		.colleagues__name{
			line-height: 1.3em
		}
	}
}

.vacancies{
	background: #f2f2f2;

	.stack{
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 30px;
		margin-bottom: 56px;

		.stack__item{
			border-style: solid;
		    border-width: 5px;
		    border-color: transparent;
		}

		.stack__item.hot{
		    border-color: rgb(255, 208, 0);


		   	.hot-vacancy{
		   		display: block;
		   		position: absolute;
		   		left: 50%;
		   		transform: translateX(-50%);
		   		top: 0;
		   		border: 2px solid rgb(255, 208, 0);
		   		border-top: unset;
		   		text-align: center;
		   		color: rgb(255, 208, 0);
		   		width: 200px;
		   		height: 30px;
		   		line-height: 30px
		   	}

		}

		.hot-vacancy{
			display: none;
		}

		img{
			width: 100%;
		}

		.vacancy__title{
			position: absolute;
			font-size: 30px;
			color: $color_text_ligt_active;
			text-align: center;
			line-height: 1.3em;
			top: 50%;
			left: 50%;
			font-weight: 400;
			transform: translate(-50%, -50%);
			width: 100%;
		}
	}
}

.blog{
	.stack{
		grid-template-columns: repeat(5, 1fr);
		grid-template-rows: 1fr 1fr;
		grid-gap: 10px;
	}

	.stack__item{
		background: silver;

		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.stack__item:first-child{
		grid-column: 1/4;
		grid-row: 1/3
	}
	.stack__item:nth-child(2){
		grid-column: 4/6;
	}

	.blog-slider:hover{
		.slick-next, .slick-prev{
			opacity: 1;
		}
	}

	.blog-slider__item, 
	.stack__item{
		display: block !important;
		&:hover{
			.mask{
				opacity: .1;
			}

			.blog-title::after{
				width: 71px;
			}
		}
	}

	.mask{
		background: #000;
		opacity: .4;
		transition: all .3s ease;
	}

	.blog-slider__item{
		height: 100%;
		position: relative;
		transition: all .3s ease;
	}

	.blog-content{
		position: absolute;
		bottom:  0;
		padding: 0 0 25px 30px;
		color: #fff;

		.blog-title, .blog-author{
			color: #fff;
			a{
				color: #fff;
			}
		}

		.blog-title{
			font-size: 18px;
			letter-spacing: 1px;
			font-weight: 700;
			font-family: "Open Sans";
			position: relative;
			margin-bottom: 30px;

			&::after{
				content: "";
				display: block;
				position: absolute;
				bottom: -18px;
				width: 28px;
				transition: all .3s ease;

    			height: 3px;
				left: 0;
				background: #fff;
			}
		}

		.blog-author{
			font-family: "PT Sans";
			font-size: 12px;
			font-style: italic;
			font-weight: 700;
			opacity: .7;
		}

		.blog-icons{
			justify-content: flex-end;
			padding-right: 30px;
			align-items: center
		}

		.blog-comments, .blog-likes{
			align-items: center;
			margin: 0 5px;

			i{
				margin-right: 5px;
			}
		}
	}
}