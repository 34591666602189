.contacts{
	background: #f2f2f2;
}

.contact-form{
	max-width: 635px;
	width: 100%;


	.form-group{
		position: relative;
		i{
			position: absolute;
			top: 5px;
			top: 12px;
    		left: 12px;
    		opacity: .7;
		}
		&__input, textarea{
			margin-bottom: 10px;
			width: 100%;
			padding: 10px 10px 10px 45px;
			font-size: 13px;
		}

		textarea{
			height: 230px;
		}
	}

	&__send{
		margin-top: 20px;
		margin-right: 0;
		margin-left: unset;
	}
}