.page-wrapper__vacancy{
	.vacancy-item{
		align-items: flex-start;
		grid-template-columns: 1fr 2fr;
		grid-column-gap: 30px;

		&:not(:last-child){
			margin-bottom: 40px;
		}

		&__title{
			line-height: 1.3em;
			text-align: right;
		}

		&__description{
			line-height: 1.9607843137254901em;

			ul{
				margin: 0;
				li{
					margin: 0;
				}
			}
		}
		.page-vacancy__btn{
			grid-column: 2/3;
			margin-left: 0;
		}
	}
}