.footer{
	padding: 25px 0 20px 0;
	background: rgb(61, 64, 69);
	.footer__copyright{
		color: rgb(140, 142, 145);
		font-size: 13px;
		display: flex;
		flex-wrap: wrap;
		a{
			color: #fff;
			font-size: 13px;
		}
	}

	.go-to-top{
		opacity: 0;
		transition: all .3s ease;
		position: fixed;
		right: 50px;
		bottom: 50px;
		font-size: 20px;
		color: #fff;
		background: rgba(0,0,0,0.7);
		width: 50px;
		height: 50px;
		text-align: center;
		line-height: 50px;
		border-radius: 3px;
		cursor: pointer;
	}
}