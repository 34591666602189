.social-sharing{
	&__list{
		margin-top: 50px !important;
	}
	li{
		width: 45px;
		height: 45px;
		background-repeat: no-repeat;
		margin: 0 5px;
		background-image: url('../img/socialshare.webp');
		transition: all .3s ease;
		cursor: pointer;

		a{
			height: 100%;
			display: block;
		}
	}

	&__fb{
		background-position: -90px -45px;
		&:hover{
			background-position: -90px 0;
		}
	}

	&__tw{
		background-position: 0 -45px;
		&:hover{
			background-position: 0 0;
		}
	}

	&__in{
		background-position: -135px -45px;
		&:hover{
			background-position: -135px 0;
		}
	}

	&__p{
		background-position: -180px -45px;
		&:hover{
			background-position: -180px 0;
		}
	}

	&__caption{
		color: #333;
		font-size: 9px;
		margin-top: 10px;
	}
}