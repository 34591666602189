.more-articles{
	margin-bottom: 50px;
	.slick-slide{
		margin: 0 5px;
	}
	&__item{
		display: block;
		&:hover{
			.gradient{
				opacity: 1;
			}

			.more-articles__category,
			.mask .fa-arrow-circle-right,
			.mask .more-articles__title
			{
				opacity: 1;
				top: 0;
				z-index: 99
			}
		}
		.mask{
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			color: #fff;
			transition: all .3s ease;
			padding-left: 20px;
			padding-right: 20px;
			background: transparent;
			* {
				color: #fff;
			}

			.fa-arrow-circle-right{
				font-size: 50px;
				margin-bottom: 50px;
				opacity: 0;
				transition: all .3s ease;				
			}
		}
		.gradient{
			background: linear-gradient(to top, #000, transparent);;
			position: absolute;
			height: 100%;
			width: 100%;
			transition: all .3s ease;
			opacity: 0;
			z-index: 1;
		}
	}

	&__category{
		opacity: 0;
		position: relative;
		top: 10px;
		transition: all .3s ease;
	}

	&__title{
		text-transform: uppercase;
		font-weight: 400;
		font-size: 24px;
		line-height: 1.2em;
		text-align: center;
		margin-bottom: 20px
	}

	&__category{
		opacity: 0;
		text-transform: uppercase;
	}

	.slick-prev, .slick-next{
	    width: 58px;
	    height: 58px;
	    line-height: 58px;
	    padding: 10px;
	    background-color: rgba(0,0,0,.4);
	    border-radius: 2px;
	    opacity: 1 !important;
	    transition: all .3s ease;

	    &:hover{
	        background-color: rgba(0,0,0,.8);
	    }
	}

	.slick-next::before,
	.slick-prev::before{
	    content: "";
	}

	.slick-next{
		right: -10px;
	}
	.slick-prev{
		left: -10px;
	}

}