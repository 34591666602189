.page-modal{
	background: #e5e5e5;
	padding: 20px 25px;
	max-width: 630px;
	width: 100%;

	.page-form{
		width: 100%;

		h2{
			margin-bottom: 25px;
		}
	}

	.form-group{
		text-align: left;
		margin-bottom: 14px;
		&__label{
			display: block;
			color: rgb(51,51,51);
			margin-bottom: 5px;
		}

		&__input{
			max-width: 300px;
			padding: 10px;
			font-size: 13px;
			width: 100%;
		}
		textarea{
			max-width: 471px;
			height: 238px;
		}

		&__btn{
			margin-left: 0;
		}
	}
}

.details-modal{
	max-width: 400px;
}