$color_main: #333;
$color_title: #3d3d3d;

$color_active: rgb(128, 188, 220);
$color_red_style: #f4524d;
$color_yellow_style: #ffd000;

$color_text_light: #b3b3b3;
$color_text_ligt_active: #fff;

html,body{
	overflow-x: hidden
}

body{
	font-family: 'PT Sans';
	font-size: 16.8px;
	line-height: 1.3em;
}

body.gap{
	padding-top: 92.6px;
}

body.active,
html.active{
	overflow-y: hidden
}

.center-x{
	margin-left: auto;
	margin-right: auto;
}

.container{
	max-width: 1140px;
	width: 100%;
	margin: 0 auto;	
}

i.fas:before{
	font-family: "FontAwesome";
	font-style: normal;
}

.fa-phone:before{
	content: "\f879";
}

.fa-bars:before{
	content: "\f0c9";
}

.fa-search:before{
	content: "\f002";
}

.fa-angle-up:before{
	content: "\f106";
}

.fa-angle-right:before{
	content: "\f105"
}

.fa-angle-left:before{
	content: "\f104";
}

.fa-plus-circle:before{
	content: "\f055";
}

.fa-user:before{
	content: "\f007"
}

.fa-envelope:before{
	content: "\f0e0"
}

.fa-pen:before{
	content: "\f304"
}

.fa-comment:before{
	content: "\f075";
}

.fa-heart:before{
	content: "\f004";
}

.fa-arrow-right:before{
	content: "\f061";
}

.fa-arrow-left:before{
	content: "\f060";
}

.fa-arrow-circle-left:before{
	content: "\f359";
}

.fa-arrow-circle-right:before{
	content: "\f35a";
}

.fa-likes:before{
	content: "\f004";
}

.fa-reply:before{
	content: "\f3e5";
}

.fa-clock:before{
	content: "\f017";
}

.fa-home:before{
	content: "\f015";
}

.d-flex{
	display: flex;
}

.flex-wrap{
	flex-wrap: wrap;
}

.d-grid{
	display: grid;
}

.align-items-center{
	align-items: center;
}

section{
	padding: 50px 0;
}

.tabs__content {
  display: none; /* по умолчанию прячем все блоки */
}
.tabs__content.active {
  display: block; /* по умолчанию показываем нужный блок */
}

.section-with-bg{
	.section-title{
		color: #fff;

		&::after{
			background: #fff;
		}
	}
}

.section-title{
	text-transform: uppercase;
	display: table;
	margin: 0 auto;
	position: relative;
	font-family: "Open Sans";
	margin-bottom: 70px;

	&::after{
		content: "";
		display: block;
		position: absolute;
		bottom: -18px;
		width: 52px;
		height: 2px;
		left: 50%;
		margin-left: -26px;
		background: $color_active;
	}
}

.btn{
	background: $color_active;
	display: table;
	cursor: pointer;
	margin: 0 auto;
	padding: 20px 28px;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 2px;
    color: $color_text_ligt_active;
    text-transform: capitalize;
    font-family: "PT Sans";
    font-weight: 700;
    transition: all .3s ease;

    &:hover{
    	background: darken($color_active, 30);
    }
}


.btn--style_red{
	background-color: $color_red_style;
	border-radius: 5px;
	letter-spacing: unset;
	font-weight: 400;
	transition: all .3s ease;
	padding: 14px 20px;

	&:hover{
    	background: darken($color_red_style, 30);
    }
}

.stack__item{
	position: relative;
	.dummy{
		margin-top: 100%;
	}

	.content-box{
		position: absolute;
	  	top: 0;
	  	bottom: 0;
	  	left: 0;
	  	right: 0;
	  	width: 100%;
	}

	.mask{
		transition: all .3s ease;
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		overflow-y: auto;
		background: #f2f2f2;
	}
}


a{
	text-decoration: none;
	display: block;
}

input,textarea,button{
	border: none;
	outline: none;
}

select{
	cursor: pointer;
}

body,a,input{
	color: $color_main;
}

h1,h2,h3,h4,h5,h6{
	color: $color_title;
	font-family: "Open Sans";
	line-height: 1.3em;
}

h3{
	font-size: 20px;
	line-height: 1.5em;
}
