.cover{
	padding: 40px 0;
	.page-title{
		color: #fff;
		line-height: 1.8em;
		font-family: "Open Sans";
		margin: 8px 0 28px 0;
		text-transform: uppercase;
	}

	.page-category{
		font-family: "Open Sans";
		color: $color_active;
		font-size: 24px;
	}

	.page-btn{
		margin-left: 0;
		color: #333;
		border: 1px solid $color_active;
		font-size: 20px;
		&:hover{
			background: transparent;
			color: $color_active;
		}
	}


	&--small{
		.page-title{
			font-size: 20px;
			font-weight: 400;
			letter-spacing: 2px;
		}
	}

	&--big{
		padding: 85px 0;
		.page-title{
			font-size: 60px;
			line-height: 1.3em;
			text-align: left;
		}

	}
}