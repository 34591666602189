.default-dsidebar{
	.search-form{
		position: relative;

		&__input{
			width: 100%;
			border-radius: 5px;
			padding: 12px 50px 12px 15px;
			border: 2px solid rgba(0,0,0,.12);
		}
		.fa-search{
			color: rgba(0,0,0,.12);	
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 20px;
		}
	}
}

.sidebar-widget{
	margin-bottom: 40px;

	&__title{
		margin-bottom: 15px;
		font-family: "PT Sans";
		text-transform: uppercase;
		font-size: 14px;
		color: #333;
	}

	li{
		font-size: 12px;
		a{
			color: #999;
		}
	}

	.cats__list{
		a{
			margin: 5px 0;
			&::before{
				margin-right: 7px;
				content: "\f105";
				font-size: 12px;
				font-family: "FontAwesome"
			}
		}
	}

	.tags__list{
		display: flex;
		flex-wrap: wrap;

		li{
			display: inline-block;
		    margin: 5px;
		    padding: 8px 10px;
		    border: 2px solid rgba(0,0,0,.1);
		    text-transform: capitalize;
		    line-height: 100%;
		    a{
		    	margin: 0;
		    }
		}
	}

	.recent-comments__item{
		font-size: 12px;
		padding: 10px 0;
		color: #999;
		&:not(:first-child){
			border-top: 1px solid rgba(128,128,128,.1);
		}
		&:before{
			margin-right: 7px;
			content: "\f086";
			font-size: 12px;
			font-family: "FontAwesome"
		}
	}
}