.article{
	h2{
		margin-bottom: 14px;
		text-transform: uppercase;
		font-size: 30px;
		line-height: 1.5em;
		text-align: center;
		font-weight: 700;
		font-family: "Open Sans";
		color: rgb(64, 64, 64);
	}

	blockquote{
		font-style: italic;
		margin: 20px 0;
		padding-left: 50px;
		position: relative;
		color: $color_active;

		&::before{
			content: "\f10d";
			font-family: "FontAwesome";
			font-style: normal;
			font-size: 35px;
			position: absolute;
			left: 0;
			top: 9px;

		}
	}

	p{
		padding: 15px 0;
		color: #000;
	}

	img{
		max-width: 100%;
		width: auto;
		margin: 15px auto;
		border-radius: 5px;
	}

	ul,ol{
		margin: 10px 0;
		display: inline-block;

		li{

			margin: 5px 0;
		}
	}


	table{
		width: fit-content;
		border-collapse: collapse;

		td{
			text-align: left;
		    vertical-align: middle;
		    border: solid 1px #ddd;
		    padding: 12px;
		}
	}
}