.page-nav{
	position: fixed;
    top: 50%;
    margin-top: -115px;
    width: 260px;
    transition: all .6s cubic-bezier(0.77,0,.175,1);
    backface-visibility: hidden;
    z-index: 999;

    &:hover{
    	right: 0;
    	i{
    		background-color: #252525;
    		width: 130px;
    	}

    	.page-nav-info{
    		top: 0;
    		opacity: 1;
    	}
    }

    &-top{
    	display: flex;

    	i{
    		display: block;
    		width: 60px;
    		line-height: 130px;
    		color: #fff;
    		text-align: center;
    		height: 130px;
    		background-color: rgba(0,0,0,.3);
    		transition: all .3s ease-out;
    	}

    	img{
    		width: 130px;
    		height: 130px;
    		object-fit: cover;
    	}
    }

    &-next{
    	right: -200px;
    }

    &-prev{
    	left: -130px;

    	&:hover{
    		left: 0;
    	}
    }

    .nav-info-container{
    	display: block;
    	height: 90px;
	    width: 260px;
	    overflow: hidden;
    }

    &-info{
    	transition: all .5s cubic-bezier(0.075,.82,.165,1) .4s;
    	top: -50px;
    	position: relative;
    	opacity: 0;
    	display: block;
    	text-align: right;
    	background: #fff;
    	height: 100%;
    }

    &-title{
    	display: block;
	    font-size: 13px;
	    font-weight: 700;
	    color: #252525;
	    line-height: 20px;
	    text-overflow: ellipsis;
	    overflow: hidden;
	    white-space: nowrap;
	    text-transform: uppercase;
	    padding: 20px 20px 0;
    }

    &-category{
	    font-weight: 500;
	    font-size: 10px;
	    text-transform: uppercase;
	    display: block;
	    color: #888;
	    letter-spacing: 1px;
	    padding: 5px 20px 0;
	    text-overflow: ellipsis;
	    overflow: hidden;
	    white-space: nowrap;
    }
}