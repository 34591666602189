.page-vacancy{
	&.hot-stuff{
		.page-vacancy__category{
			color: $color_yellow_style;
		}

		.page-vacancy__btn{
			color: #333;
			background: $color_yellow_style;
			border-color: $color_yellow_style;

			&:hover{
				background: transparent;
				color: $color_yellow_style;
			}
		}
	}
}