@import "base/reset";
@import "base/specification";

@import "vendor/remodal";
@import "vendor/slick";

@import "layouts/header";
@import "layouts/cover";
@import "layouts/social-sharing";
@import "layouts/article";
@import "layouts/card";
@import "layouts/page-nav";
@import "layouts/contacts";
@import "layouts/cta";
@import "layouts/promo";
@import "layouts/more-articles";
@import "layouts/sidebar";
@import "layouts/pagination";
@import "layouts/modal";
@import "layouts/timeline";
@import "layouts/footer";

@import "pages/home";
@import "pages/works";
@import "pages/themes";
@import "pages/themes-article";
@import "pages/blog";
@import "pages/contacts";
@import "pages/vacancy";

@import "base/responsive";