@media (max-width: 1140px){
	.container{
		padding: 0 10px;
	}

	.header{
		.nav__item{
			padding: 0 10px;
		}


		.banner{
			overflow: hidden;

			& > img{
				position: relative;
				left: -80px;
			}
		}

		.banner__text{
			margin-left: -100px
		}

		.banner .banner-word--2nd, .banner .banner-word--3td{
			font-size: 26px;
		}

		.banner .banner-word--4th, .banner .banner-word--5th{
			font-size: 43px;
		}


	}
}

@media (max-width: 992px){
	body{
		font-size: 13px;
	}
	h1{
		font-size: 30px !important;
	}
	h3{
		font-size: 20px !important;
	}
	section{
		padding: 50px 0;
	}
	body.gap{
		padding-top: 64px;
	}
	.header{
		.heading-wrapper{
			padding: 20px 0 10px 0;
		}
		.nav{
			display: none;
		}
		.burger{
			display: block;
			font-size: 16px;
		}
		.search-button{
			margin: 0 20px 0 auto;
			font-size: 16px;
		}
		.banner{
			justify-content: flex-start;

			&__text{
				margin-left: 0;
			}

			.banner-word--4th, .banner-word--5th{
				font-size: 32px;
			}

			.banner-word--6th,
			.banner-word--2nd,
			.banner-word--3td{
				line-height: 1.52em;
				font-size: 22px;
			}
		}
		.banner__links{
			display: none;
		}
	}

	.themes .stack{
		grid-template-columns: 1fr 1fr
	}

	.colleagues .stack{
		grid-template-columns: 1fr 1fr 1fr
	}

	.video .stack{
		grid-template-columns: 1fr 1fr;

		iframe{
			max-width: 100%;
			width: 100%;
		}
	}

	.blog{
		.stack{
			grid-template-columns: 1fr 1fr 1fr 1fr;
			grid-template-rows: 1fr
		}
		.stack__item:first-child{
			grid-row: 1/2;
    		grid-column: 1/5;
		}
		.stack__item:nth-child(2){
			grid-column: 1/3;
			height: 200px;
		}
		.stack__item:nth-child(3){
		    grid-column: 3/4;
		    height: 200px;
		}
		.stack__item:last-child{
			grid-column: 4/5;
			height: 200px;
		}
		& &-content{
			padding: 0 0 10px 10px;

			.blog-title{
				font-size: 14px !important;
				margin-bottom: 13px;

				&::after{
					bottom: -8px;
					height: 1px;
				}
			}
			.blog-author{
				font-size: 12px;
			}
			.blog-icons{
				font-size: 12px;
				padding-right: 0;
			}

		}
	}


	// PAGE THEMES
	.page-wrapper--themes_page{
		grid-template-columns: 1fr;

		.page-sidebar .filter{
			display: flex;
    		align-items: flex-end;
    		margin-bottom: 30px;

    		&__item{
    			margin-bottom: 0;
    			margin-right: 20px;
    		}

    		&__send-btn{
    			margin-bottom: 15px;
    			margin-left: 0;
    		}
		}

		.page-stack{
			grid-template-columns: 1fr 1fr
		}
	}


	// PAGE ARTICLE
	.article-author{
		width: 30%;
		margin-right: 10px;
	}
	.acricle-contact-form{
		margin-left: 10px;
		width: 70%;
	}

	.more-articles__item{
		.mask,.more-articles__title,.more-articles__category{
			opacity: 1;
			top: 0;
		}
		.fa-arrow-circle-right{
			display: none;
		}
	}

	// BLOG ARCHIVE
	.page-wrapper--blog_page{
		grid-template-columns: 1fr;

		.stack{
			grid-template-columns: repeat(3, 1fr)
		}
		.card{
			&__title{
				font-size: 14px !important;
			}
		}
	}

	.pagination{
		grid-column: 1/2;
		margin: 20px 0;
		grid-row: unset;
	}
}

@media (max-width: 768px){
	.footer .go-to-top{
		right: 20px;
    	bottom: 20px;
	}
	.cover--big{
		padding: 30px 0;
	}
	.section-title{
		margin-bottom: 50px;
	}
	.header{
		.banner{
			& > img{
				width: 300px;
			}

			.banner .banner-word--5th{
				margin-top: 20px;
			}
		}
	}

	.page-nav{
		display: none;
	}

	.stack{
		grid-gap: 5px !important;
	}

	.vacancies .stack {
	    grid-template-columns: repeat(2, 1fr);
	}

	.testi .stack{
		grid-row-gap: 30px;
	}
	.testi .stack__item .testi__text{
		font-size: 13px;
	}
	.testi .stack__item .testi__text,
	.testi .stack__item .testi__author{
		padding: 0;
	}

	.colleagues .stack{
		grid-template-columns: 1fr 1fr;

		&__item{
			img{
				width: 100%;
			}
		}
	}

	.blog{
		.stack__item:nth-child(2){
			grid-column: 1/3;
		}
		.stack__item:nth-child(3){
		    grid-column: 3/5;
		}
		.stack__item:last-child{
			grid-column: 1/5;
		}
	}




	.article{
		h2{
			font-size: 22px;
		}
	}


	// CALL TO ACTION BLOCK
	.cta{
		&-form__input{
			width: 100%;
			margin-bottom: 15px;
		}
	}

	// PROMO BLOCK
	.promo{
		&-block{
			flex-direction: column;
			padding-left: 20px;
			padding-right: 20px;
			padding-bottom: 20px;

			& > *{
				width: 100%;
			}

			&__right{
				margin-top: 20px;
			}

			&__link{
				margin-bottom: 10px;
			}

			&--hot_stuff{
				width: 100%;
			}

			&--hot_stuff__offer{
				left: 50%;
				transform: translateX(-50%);
				width: 98%;
			}
		}
	}

	// BLOG ARCHIVE
	.page-wrapper--blog_page{

		.stack{
			grid-template-columns: repeat(2, 1fr)
		}

		.pagination{
			width: 100%;
    		justify-content: space-around;
		}
	}


	// BLOG ARTICLE PAGE
	.more-articles{
		margin: 25px 0;
		
		ul{
			flex-wrap: wrap;

			li{
				width: 50%;
				margin-bottom: 10px;
			}
		}

		&__item{
			
			margin: 0 5px;

			.mask{
				padding: 10px;
			}
		}
		&__title{
			font-size: 16px;
		}
	}


	// CONTACTS PAGE
	.contacts-page{
		grid-column-gap: 0;
		grid-template-columns: 1fr;
	}
}

@media (max-width: 600px){

	.search-form .input-group__input{
		font-size: 22px;
		padding: 13px;
		padding-right: 30px;
	}
	.search-form .input-group i.fa-search{
		font-size: 18px;
	}
	.cover--small{
		.page-title{
			font-size: 20px !important;
			line-height: 1.2em;
		}
	}
	h1{
		font-size: 22px !important;
	}
	h3{
		font-size: 18px !important;
	}
	.header .banner > img{
		left: 0;
		width: 200px;
	}
	.header .banner .banner-word--4th, .header .banner .banner-word--5th{
		font-size: 24px;
	}
	.banner .banner-word--5th{
		margin-bottom: 30px;
	}
	.banner .banner-word--4th{
		margin-top: 30px;
	}
	.header .banner .banner-word--6th, .header .banner .banner-word--2nd, .header .banner .banner-word--3td{
		font-size: 17px;
	}

	.page-works{
		.stack:not(.brands-stack){
			grid-template-columns: 1fr 1fr;
		}
	}


	// PAGE THEMES
	.page-wrapper--themes_page{
		.page-sidebar .filter{
			flex-direction: column;
    		align-items: flex-start;

    		&__item{
    			margin-right: 0;
    			margin-bottom: 20px;
    			width: 100%;
    		}

    		&__send-btn{
    		}
		}

		.page-stack{
			grid-template-columns: 1fr;

			.stack__item{
				max-height: 200px;
			}
		}
	}


	// PAGE ARTICLE
	.page-wrapper--themes_article_page .tabs__caption{
		justify-content: flex-start;
		li{
			border-bottom: 1px solid #e1e0e0;
			&:last-child{
				border-right: 1px solid #e1e0e0;
			}
		}
	}
	.article-author{
		width: 40%;
	}
	.acricle-contact-form{
		width: 60%;
	}

	// VACANCY ARTICLE
	.cover .page-category{
		font-size: 14px;
	}
	.cover .page-btn, .cover .page-vacancy__btn{
		font-size: 13px;
		letter-spacing: 1px;
		padding: 14px 20px;
	}

	.page-vacancy .page-wrapper__vacancy .vacancy-item{
		grid-template-columns: 1fr;
		grid-gap: 0;
		h2{
			text-align: left;
		}
	}
	.page-vacancy .page-wrapper__vacancy .vacancy-item .page-vacancy__btn{
		grid-column: 1/3;
		font-size: 13px;
	    letter-spacing: 1px;
	    padding: 14px 20px;
	}
}

@media (max-width: 500px){
	.fadeIn{
		animation: unset;
		opacity: 1;
	}
	.header{
		.banner{
			flex-direction: column-reverse;
		    align-items: flex-start;
		    margin: 0 -10px;

		    & > img{
		    	width: 300px;
		    }

		    .banner__text{
		    	padding: 20px;
		    }
		}
	}
	.testi .stack{
		grid-row-gap: 30px !important
	}
	.themes .stack,
	.video .stack,
	.testi .stack,
	.colleagues .stack,
	.vacancies .stack{
		grid-template-columns: 1fr;		
	}
	.blog .stack__item{
		grid-column: 1/5 !important
	}
	.testi .stack{
		&__item{
			min-height: 200px
		}
	}


	// PAGE ARTICLE
	.article-author,
	.acricle-contact-form{
		width: 100%;
		margin-left: 0;
		margin-right: 0;
		margin-bottom: 20px;
	}

	.article-author{
		.card__thumbnail{
			width: 50%;
		}
	}

	// CALL TO ACTION BLOCK
	.cta{
		&-form__btn{
			width: 100%;
		}
	}

	// BLOG ARCHIVE
	.page-wrapper--blog_page{
		grid-gap: 10px;
		.stack{

			grid-template-columns: repeat(1, 1fr)
		}
	}

	// WIDGETS
	.sidebar-widget{
		margin-bottom: 20px;
	}

	.more-articles{
		ul{
			li{
				width: 100%;
			}
		}
	}
}